import React from 'react';
import Button from '@dr-pam/common-components/Components/Form/Button';
import Image from 'next/image';
import { CategoryWithSubCategories } from '@dr-pam/common-components/Services/Server/CategoryService';

type QuizStepAgeProps = {
	onSelected: (item: number) => void;
	data?: CategoryWithSubCategories[];
};

export default function QuizStepAge(props: QuizStepAgeProps) {
	const { data, onSelected } = props;

	return (
		<div className="quiz-age-container">
			{data?.map((category, index) => (
				<Button key={index} className="solid outline reverse light icon" onClick={() => onSelected(index)}>
					<Image src={`/images/quiz/${category.slug}.svg`} width={30} height={30} alt={category.slug} />
					<p>{category.name}</p>
				</Button>
			))}
		</div>
	);
}
