'use client';
import React from 'react';
import { useModalProvider } from '@dr-pam/common-components/Components/Modal/ModalProvider';
import { MouseEvent, ReactNode } from 'react';
import { showPurchaseSubscriptionModal } from '../subscription/PurchaseSubscriptionModal';
import Link from 'next/link';
import { useSubscriptionTypes } from '../subscription/SubscriptionTypesProvider';

type SignUpButtonProps = {
	className?: string;
	children?: ReactNode;
};

export default function SignUpButton(props: SignUpButtonProps) {
	const { children, className } = props;
	const { subscriptionTypes } = useSubscriptionTypes();

	const modalProvider = useModalProvider();

	const handleExploreClicked = (e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		showPurchaseSubscriptionModal(modalProvider, {
			modalId: 'subscription-options',
			subscriptionTypes,
		});
	};

	return (
		<Link href="#" className={className} onClick={handleExploreClicked}>
			{children}
		</Link>
	);
}
