import React, { MouseEvent } from 'react';
import { CommonSleepQuestionType } from './CommonSleepQuestions';
import ArticleCardClientSide from '../article/ArticleCardClientSide';
import { IconMinus, IconPlus } from '@tabler/icons-react';

type CommonSleepQuestionItemProps = {
	active: boolean;
	onToggle: () => void;
	data: CommonSleepQuestionType;
};

export default function CommonSleepQuestionItem(props: CommonSleepQuestionItemProps) {
	const { active, onToggle, data } = props;
	const { title, article, content } = data;

	const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		onToggle();
	};

	return (
		<div className={`common-sleep-question ${active ? 'active' : ''}`}>
			<div className="csq-title" onClick={handleToggle}>
				<h4>{title}</h4>
				<div className="expand-icon">
					{active ? <IconMinus size={48} stroke={1} /> : <IconPlus size={48} stroke={1} />}
				</div>
			</div>
			{active && (
				<div className="csq-content">
					{article && (
						<ArticleCardClientSide
							title={article.title}
							category={article.category}
							bannerImage={article.bannerImage}
							programmeSlug={article.programmeSlug}
							categorySlug={article.categorySlug}
							articleSlug={article.articleSlug}
						/>
					)}
					{content}
				</div>
			)}
		</div>
	);
}
