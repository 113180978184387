'use client';

import React, { useContext } from 'react';
import { ProductSubscriptionType } from '@dr-pam/common-types/database';
import { createContext, ReactNode } from 'react';

type SubscriptionTypesContextType = {
	subscriptionTypes: ProductSubscriptionType[];
};

const SubscriptionTypesContext = createContext<SubscriptionTypesContextType | null>(null);

type SubscriptionTypesProviderProps = {
	subscriptionTypes: ProductSubscriptionType[];
	children: ReactNode;
};

export default function SubscriptionTypesProvider(props: SubscriptionTypesProviderProps) {
	const { subscriptionTypes, children } = props;

	return (
		<SubscriptionTypesContext.Provider value={{ subscriptionTypes }}>{children}</SubscriptionTypesContext.Provider>
	);
}

export function useSubscriptionTypes() {
	const context = useContext(SubscriptionTypesContext);
	if (!context) {
		throw new Error('useSubscriptionTypes must be used within a SubscriptionTypesProvider');
	}
	return context;
}
