import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { CategoryWithSubCategories } from '@dr-pam/common-components/Services/Server/CategoryService';

const SLUG_IMAGE_MAP = [
	{ slug: 'sleep-emergency', image: '/images/quiz/quiz-emergency.svg' },
	{ slug: 'daytime-sleep-problem', image: '/images/quiz/quiz2.svg' },
	{ slug: 'evening-sleep-problem', image: '/images/quiz/quiz4.svg' },
	{ slug: 'only-sleeps-when-held-or-carried', image: '/images/quiz/quiz6.svg' },
	{ slug: 'excessive-night-waking', image: '/images/quiz/quiz1.svg' },
	{ slug: 'waking-too-early', image: '/images/quiz/quiz3.svg' },
	{ slug: 'how-can-i-sleep-my-baby-safely', image: '/images/quiz/quiz5.svg' },
	{ slug: 'how-can-i-take-care-of-myself-how-can-others-help-me', image: '/images/quiz/quiz7.svg' },
	//Toddler images
	{ slug: 'toddler-sleep-emergency', image: '/images/quiz/quiz-emergency.svg' },
	{ slug: 'toddler-daytime-sleep-problem', image: '/images/quiz/quiz2.svg' },
	{ slug: 'toddler-evening-sleep-problem', image: '/images/quiz/quiz4.svg' },
	{ slug: 'toddler-excessive-night-waking', image: '/images/quiz/quiz1.svg' },
	{ slug: 'toddler-waking-too-early', image: '/images/quiz/quiz3.svg' },
	{ slug: 'toddler-only-sleeps-when-held-or-carried', image: '/images/quiz/quiz6.svg' },
	{ slug: 'toddler-how-can-i-take-care-of-myself-how-can-others-help-me', image: '/images/quiz/quiz7.svg' },
];

type QuizStepChallengeProps = {
	challenges: CategoryWithSubCategories[];
	programmeSlug?: string;
};

export default function QuizStepChallenge(props: QuizStepChallengeProps) {
	const { challenges, programmeSlug } = props;

	return (
		<div className="quiz-challenge-container">
			{challenges.map((c, index) => {
				const { name, sortOrder, slug } = c;
				const emergency = sortOrder === 0;
				const className = emergency ? 'danger icon' : 'outline light reverse icon';
				const image = SLUG_IMAGE_MAP.find((s) => slug === s.slug)?.image ?? '/images/quiz/quiz1.svg';

				return (
					<Link href={`${programmeSlug}/${slug}`} className={`Button solid item ${className}`} key={index}>
						<Image src={image} width={30} height={30} alt="icon" />
						<p>{name}</p>
					</Link>
				);
			})}
		</div>
	);
}
