'use client';
import React from 'react';
import { useCarousel } from '@dr-pam/common-components/Hooks/useCarousel';
import ArticleCardClientSide, { ArticleCardProps } from './ArticleCardClientSide';
import Image from 'next/image';

type FeaturedArticlesClientSideProps = {
	articles: ArticleCardProps[];
	className?: string;
};

export default function FeaturedArticlesClientSide(props: FeaturedArticlesClientSideProps) {
	const { articles, className } = props;
	const articleData = articles.filter((a) => a !== null);
	const { carouselElRef, itemRefs, scrollToNext, scrollToPrevious } = useCarousel({
		items: articles,
	});

	return (
		<div className={`featured-article-container ${className}`}>
			<div ref={carouselElRef} className="featured-article-carousel">
				{articleData.map((article, index) => (
					<div key={index} className="featured-article-item" ref={(ref) => (itemRefs.current[index] = ref)}>
						<ArticleCardClientSide {...article} />
					</div>
				))}
			</div>
			<div className="carousel-controls">
				<button className="carousel-button prev-btn" onClick={scrollToPrevious}>
					<Image src="/images/icons/ArrowLine_Left-dark.svg" alt="arrow left" width={80} height={20} />
				</button>
				<button className="carousel-button next-btn" onClick={scrollToNext}>
					<Image src="/images/icons/ArrowLine_Right-dark.svg" alt="arrow right" width={80} height={20} />
				</button>
			</div>
		</div>
	);
}
