import React from 'react';
import { useState } from 'react';
import Breadcrumb from './Breadcrumb';
import QuizStepAge from './QuizStepAge';
import QuizStepChallenge from './QuizStepChallange';
import { IconArrowBackUp, IconX } from '@tabler/icons-react';
import { ProgrammeWithCategories } from '@dr-pam/common-components/Services/Server/ProgrammeService';
import { CategoryWithSubCategories } from '@dr-pam/common-components/Services/Server/CategoryService';
import { useRouter } from 'next/navigation';

interface QuizFormProps {
	onClose: () => void;
	data?: ProgrammeWithCategories;
}

export default function QuizForm(props: QuizFormProps) {
	const { data, onClose } = props;
	const [currentStep, setCurrentStep] = useState(0);
	const [question, setQuestion] = useState(data?.categories[0].name);
	const [challenges, setChallenges] = useState<CategoryWithSubCategories[]>([]);
	const router = useRouter();

	const onAgeSelected = (item: number) => {
		if (item === 0) {
			router.push(`/${data?.slug}/${data?.categories[0].subCategories[item].slug}`);
		} else {
			setCurrentStep(1);
			const subCategory = data?.categories[0].subCategories[item].subCategories[0];
			if (subCategory) {
				setQuestion(subCategory.name);
				setChallenges(subCategory.subCategories);
			}
		}
	};

	const handleBackPressed = () => {
		if (currentStep == 1) {
			setCurrentStep(0);
			setQuestion(data?.categories[0].name);
		}
	};

	return (
		<div className={`quiz-form-container ${currentStep === 0 ? 'with-background' : ''}`}>
			<div className="quiz-header">
				<div className="flex jb ac w100">
					<IconArrowBackUp
						className={`controller ${currentStep === 0 ? 'invisible' : ''}`}
						onClick={handleBackPressed}
					/>
					<IconX className="controller" onClick={onClose} />
				</div>
				<Breadcrumb step={currentStep} />
			</div>
			<h2 className="title">{question}</h2>
			{currentStep === 0 ? (
				<QuizStepAge onSelected={onAgeSelected} data={data?.categories[0].subCategories} />
			) : (
				<QuizStepChallenge challenges={challenges} programmeSlug={data?.slug} />
			)}
		</div>
	);
}
