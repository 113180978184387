import React from 'react';

type BreadcrumProps = {
	step: number;
};

const BREADCRUMBS = ['Age?', 'Challenges?', 'Answers'];

export default function Breadcrumb(props: BreadcrumProps) {
	const { step } = props;

	return (
		<div className="breadcrumbs">
			{BREADCRUMBS.map((b, index) => (
				<span key={index} className={`item ${step === index && 'active'}`}>
					{b}
				</span>
			))}
		</div>
	);
}
