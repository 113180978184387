'use client';
import React from 'react';
import JournalLogo from './JournalLogo';
import { useState } from 'react';
import { JournalLogoData } from './JournalLogoData';

export default function JournalLogoContainer() {
	const [active, setActive] = useState(-1);

	const onToggle = (item: number) => {
		setActive(active === item ? -1 : item);
	};
	return (
		<div className="journal-logo-container">
			{JournalLogoData.map((journal, index) => (
				<JournalLogo key={index} active={active === index} onToggle={() => onToggle(index)} data={journal} />
			))}
		</div>
	);
}
