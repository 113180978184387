import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { IconCircleArrowUpRight } from '@tabler/icons-react';

export interface ArticleCardProps {
	title?: string;
	category?: string;
	bannerImage?: string;
	categorySlug?: string;
	programmeSlug?: string;
	articleSlug: string;
}

export default function ArticleCardClientSide(props: ArticleCardProps) {
	const { title, category, bannerImage, categorySlug, programmeSlug, articleSlug } = props;

	return (
		<div className="article-card dark-bg">
			<div className="featured-image">
				{bannerImage && <Image src={bannerImage} alt="article-thumbnail-1" width={0} height={0} unoptimized />}
				<div className="category">
					<p>{category}</p>
				</div>
			</div>
			<div className="content">
				<div className="title">
					<h3>{title}</h3>
				</div>
				{!!programmeSlug && !!categorySlug && !!articleSlug && (
					<Link href={`${programmeSlug}/${categorySlug}/${articleSlug}`} className="read-more">
						<div className="icon-wrapper">
							<Image
								src="/images/icons/circle-arrow.svg"
								className="icon-normal"
								width={23}
								height={23}
								alt="circle-arrow-normal"
							/>
							<Image
								src="/images/icons/circle-arrow-hover.svg"
								className="icon-hover"
								width={23}
								height={23}
								alt="circle-arrow-hover"
							/>
						</div>
						<p>Read more</p>
					</Link>
				)}
			</div>
		</div>
	);
}
