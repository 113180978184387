'use client';
import React from 'react';
import { useState } from 'react';
import { CommonSleepQuestionType } from './CommonSleepQuestions';
import CommonSleepQuestionItem from './CommonSleepQuestionItem';

interface CommonSleepQuestionsClientSideProps {
	data: CommonSleepQuestionType[];
}

export default function CommonSleepQuestionsClientSide(props: CommonSleepQuestionsClientSideProps) {
	const { data } = props;
	const [active, setActive] = useState<string | null>(null);

	return (
		<div className="common-sleep-questions">
			{data.map((commonSleepQuestion) => {
				const { id } = commonSleepQuestion;
				return (
					<CommonSleepQuestionItem
						key={id}
						active={active === id}
						onToggle={() => {
							setActive(active === id ? null : id);
						}}
						data={commonSleepQuestion}
					/>
				);
			})}
		</div>
	);
}
