import React from 'react';
import { JournalLogoType } from './JournalLogoData';
import Image from 'next/image';
import { IconMinus, IconPlus } from '@tabler/icons-react';

type JournalLogoProps = {
	data: JournalLogoType;
	active: boolean;
	onToggle: () => void;
};

export default function JournalLogo(props: JournalLogoProps) {
	const {
		data: { image, tooltipTitle, tooltipContent },
		active,
		onToggle,
	} = props;
	return (
		<div className={`journal-logo__container ${active ? 'active' : ''}`}>
			<div className="journal-logo-image" onClick={onToggle}>
				<Image src={image} className="journal-logo" alt="journal-logo1" width={0} height={0} unoptimized />
				<div className="expand-icon">
					{active ? <IconMinus size={28} stroke={1} /> : <IconPlus size={28} stroke={1} />}
				</div>
			</div>
			<span className="journal-logo__tooltip">
				<p className="journal-logo__tooltip-title">{tooltipTitle}</p>
				<p className="journal-logo__tooltip-content">{tooltipContent}</p>
			</span>
		</div>
	);
}
