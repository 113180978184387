'use client';

import React from 'react';
import Button from '@dr-pam/common-components/Components/Form/Button';
import Image from 'next/image';
import QuizForm from './QuizForm';
import { useModalProvider } from '@dr-pam/common-components/Components/Modal/ModalProvider';
import { ProgrammeWithCategories } from '@dr-pam/common-components/Services/Server/ProgrammeService';

type QuizButtonProps = {
	data?: ProgrammeWithCategories;
};

export default function QuizButton(props: QuizButtonProps) {
	const { data } = props;
	const modalProvider = useModalProvider();

	const showQuizForm = () => {
		modalProvider.openModal({
			modalId: 'quiz-modal',
			className: 'blur',
			showClose: false,
			children: <QuizForm onClose={hideQuizForm} data={data} />,
		});
	};

	const hideQuizForm = () => {
		modalProvider.closeModal('quiz-modal');
	};

	return (
		<>
			<Button className="solid light icon quiz-button" onClick={showQuizForm}>
				<Image src="/images/icons/navigate.svg" width={34} height={34} alt="navigate-icon" />
				Find Answers Now
			</Button>
		</>
	);
}
