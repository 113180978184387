'use client';
import React from 'react';
import Image from 'next/image';
import { useCarousel } from '@dr-pam/common-components/Hooks/useCarousel';

export type Testimonial = {
	id: number;
	name: string;
	occupation?: string;
	location: string;
	text: string;
};

export type TestimonialsProps = {
	className?: string;
	testimonials: Testimonial[];
};

export default function Testimonials(props: TestimonialsProps) {
	const { className, testimonials } = props;
	const { carouselElRef, itemRefs, scrollToNext, scrollToPrevious } = useCarousel({
		items: testimonials,
	});

	return (
		<div className={`testimonial-container ${className ?? ''}`}>
			<div ref={carouselElRef} className="testimonial-carousel">
				{testimonials.map((testimonial, index) => (
					<div
						key={testimonial.id}
						className="testimonial-item"
						ref={(ref) => (itemRefs.current[index] = ref)}
						aria-labelledby={`testimonial-title-${index}`}
						aria-describedby={`testimonial-desc-${index}`}
					>
						<p id={`testimonial-desc-${index}`} className="testimonial-text quote-style">
							{testimonial.text}
						</p>
						<p id={`testimonial-title-${index}`} className="testimonial-author">
							<span className="name">{testimonial.name}</span>
							{testimonial.occupation && (
								<>
									, <span className="occupation">{testimonial.occupation}</span>
								</>
							)}
							, <span className="location">{testimonial.location}</span>
						</p>
					</div>
				))}
			</div>
			<div className="carousel-controls">
				<button
					className="carousel-button prev-btn"
					onClick={scrollToPrevious}
					aria-label="Previous Testimonial"
				>
					<Image src="/images/icons/ArrowLine_Left.svg" alt="arrow left" width={80} height={20} />
				</button>
				<button className="carousel-button next-btn" onClick={scrollToNext} aria-label="Next Testimonial">
					<Image src="/images/icons/ArrowLine_Right.svg" alt="arrow right" width={80} height={20} />
				</button>
			</div>
		</div>
	);
}
