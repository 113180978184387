export type JournalLogoType = {
	image: string;
	tooltipTitle: string;
	tooltipContent: string;
};

export const JournalLogoData: JournalLogoType[] = [
	{
		image: '/images/icons/journal-logo1.png',
		tooltipTitle:
			'The findings of the present study provide initial evidence for the effectiveness of the Possums Sleep Intervention.',
		tooltipContent: `Whittingham et al (2020) 'Evaluating the “possums” health professional training in parent–infant sleep', Infant Mental Health Journal, 41(5), pp. 618-631.`,
	},
	{
		image: '/images/icons/journal-logo2.png',
		tooltipTitle:
			'...Acceptable to parents and perceived by them to align parent and infant needs, improve their quality of life, and reduce their focus on perceived infant sleep problems.',
		tooltipContent: `Ball et al (2018) 'The Possums Infant Sleep Program: parents' perspectives on a novel parent-infant sleep intervention in Australia', Sleep Health: Journal of the National Sleep Foundation, 4(6), pp. 519-526.`,
	},
	{
		image: '/images/icons/journal-logo3.png',
		tooltipTitle:
			'... a clear positive effect on the quality of emotional availability in the relationship, at least as perceived by mothers. As such, the intervention appears to have a positive effect on the environment that supports good sleep.',
		tooltipContent: `Closson et al (2020) 'Evaluation of possums sleep intervention: A pilot feasibility study', Journal of Nursing Education and Practice, 10(2), pp. 15–22.`,
	},
	{
		image: '/images/icons/journal-logo4.png',
		tooltipTitle:
			'... a promising tool for promoting parental attitude and behaviour-change, that aims to adjust parental expectations and reduce negative thinking around infant sleep, promote responsive infant care in the face of infant-related sleep disruption and fatigue, and support parental well-being during the first year of parenthood.',
		tooltipContent: `Ball et al (2020) 'Development and evaluation of "Sleep, Baby & You"—An approach to supporting parental well-being and responsive infant caregiving', Plos One, 15(8), e0237240.`,
	},
	{
		image: '/images/icons/journal-logo5.png',
		tooltipTitle:
			'The Possum infant sleep program provided equivalent positive results on sleep parameters compared to usual care while advocating a more cued response. The critical difference was evident in sustained breastfeeding.',
		tooltipContent: `Öztürk, et al (2021) 'Possums-based parental education for infant sleep: cued care resulting in sustained breastfeeding', European Journal of Pediatrics, 180(6), pp. 1769–1776.`,
	},
];
